export const Currencies = [
    {
        currency: 'New Zealand Dollar',
        symbol: 'NZD'
    },
    {
        currency: 'Australian Dollar',
        symbol: 'AUD'
    },
    {
        currency: 'Fijian Dollar',
        symbol: 'FJD'
    },
    {
        currency: 'United States Dollar',
        symbol: 'USD'
    },
    {
        currency: 'Samoan Tala',
        symbol: 'WST'
    },
    {
        currency: 'Tongan Pa\'anga',
        symbol: 'TOP'
    },
    {
        currency: 'United Kingdom British Pound',
        symbol: 'GBP'
    },
    {
        currency: 'Papua New Guinean Kina',
        symbol: 'PGK'
    },
    {
        currency: 'Ni-Vanuatu Vatu',
        symbol: 'VUV'
    },
    {
        currency: 'Sri Lankan Rupee',
        symbol: 'LKR'
    },
    {
        currency: 'Solomon Islander Dollar',
        symbol: 'SBD'
    }
]

export function GetCurrencyBySymbol(currency) {
    if(currency === 'NZD'){
        return '(NZD) New Zealand Dollar'
    }
    if(currency === 'AUD'){
        return '(AUD) Australian Dollar'
    }
    if(currency === 'FJD'){
        return '(FJD) Fijian Dollar'
    }
    if(currency === 'USD'){
        return '(USD) United States Dollar'
    }
    if(currency === 'WST'){
        return '(WST) Samoan Tala'
    }
    if(currency === 'TOP'){
        return '(TOP) Tongan Pa\'anga'
    }
    if(currency === 'GBP'){
        return '(GBP) United Kingdom British Pound'
    }
    if(currency === 'PGK'){
        return '(PGK) Papua New Guinean Kina'
    }
    if(currency === 'VUV'){
        return '(VUV) Ni-Vanuatu Vatu'
    }
    if(currency === 'LKR'){
        return '(LKR) Sri Lankan Rupee'
    }
    if(currency === 'SBD'){
        return '(SBD) Solomon Islander Dollar'
    }
}

