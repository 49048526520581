<template>
  <!-- table row -->
  <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 table-container-row"
      no-gutters
  >
    <b-table
        ref="questionsTable"
        :bordered="false"
        :borderless="true"
        select-mode="single"
        class="table-element"
        thead-class="table-header font-info"
        tbody-class="table-body"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="itemsHeader"
        :items="productItems"
    >
      <!-- table busy state -->
      <!-- <template #table-busy>
        <div class="text-center text-muted my-3">
          <b-spinner class="align-middle"></b-spinner>
          <span class="text-left mx-1">Loading...</span>
        </div>
      </template> -->
      <!-- table empty state -->
      <template #empty="scope">
        <b-row
            class="d-flex flex-row align-items-center justfiy-content-center py-5"
            no-gutters
        >
          <h6 class="text-center mb-0 mx-auto my-5">
            {{ scope.emptyText }}
          </h6>
        </b-row>
      </template>
      <!-- customer Name  -->
      <template #cell(itemName)="data">
        <b-col
            class="d-flex flex-column align-items-start justify-content-center px-0"
        >
          <!-- <b-button
            variant="white"
            class="d-flex flex-row align-items-center justify-content-center mx-0 my-lg-0 px-2 pb-2 shadow-none text-main-green item-plus-btn"
            pill
            @click="isEditInvoice = true"
          >
            <b-icon
              class="text-main-green mr-3"
              icon="pencil-fill"
              size="sm"
              aria-label="Plus"
              font-scale="0.9"
            ></b-icon>
            <span>Edit Columns</span>
          </b-button> -->
          <span class="main-text">{{ data.value.title }}</span>
        </b-col>
      </template>
      <!-- GL Code -->
      <template #cell(glcode)="data">
        <span class="main-text mb-5">{{ data.value }}</span>
      </template>
      <!-- customer Email -->
      <template #cell(itemDescription)="data">
        <span class="main-text pt-5">{{ data.value }}</span>
      </template>
      <!-- customer Email -->
      <template #cell(quantity)="data">
        <span class="main-text mb-2">{{ data.value }}</span>
      </template>
      <!-- UoM  -->
      <template #cell(uom)="data">
        <span class="main-text mb-2">{{ data.value }}</span>
      </template>
      <!-- customer Amount  -->
      <template #cell(totalAmount)="data">
        <span class="main-text mb-2">{{ data.item.itemPrice }}</span>
      </template>
      <!-- items actions (edit/delete)  -->
      <template #cell(actions)="data">
        <!-- delete item button -->
        <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100"
            no-gutters
        >
          <b-col cols="12" md="6" class="pr-2">
            <b-button
                v-b-tooltip.hover.right
                title="Edit Item"
                class="shadow-none mx-1 border border-success edit-btn"
                pill
                @click="editItem(data)"
            >
              <b-icon
                  icon="pen"
                  aria-label="Edit Item"
                  class="edit-icon"
              ></b-icon>
            </b-button>
          </b-col>
          <b-col cols="12" md="6" class="pr-2">
            <b-button
                v-b-tooltip.hover.right
                title="Remove Item"
                class="shadow-none mx-1 border border-danger delete-btn"
                pill
                @click="removeItem(data)"
            >
              <b-icon
                  icon="trash"
                  aria-label="Remove Item"
                  class="delete-icon"
              ></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-table>
    <template v-if="isNewItem">
      <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100"
          no-gutters
      >
        <b-col cols="12" md="2" class="pr-2">
          <FormInput
              groupId="user-name-group-item-name"
              id="user-name-group-input-item-name"
              class="text-prime-gray rounded-2"
              :state="itemNameError.status"
              :invalidFeedback="`${itemNameError.message}`"
              label="Item Name"
              v-model="itemName"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
          ></FormInput>
        </b-col>
        <b-col cols="12" md="2" class="pr-2">
          <FormSelect
              groupId="user-glcode-group"
              id="user-glcode-input"
              class="text-prime-gray rounded-2"
              :state="glCodeError.status"
              :invalidFeedback="`${glCodeError.message}`"
              label="GL Code"
              v-model="glcode"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
              :options="glOptions"
          ></FormSelect>
        </b-col>
        <b-col cols="12" md="2" class="pr-2">
          <FormInput
              groupId="user-Description-group"
              id="user-Description-group-input"
              class="text-prime-gray rounded-2"
              label="Description"
              v-model="itemDescription"
              :state="itemDescriptionError.status"
              :invalidFeedback="`${itemDescriptionError.message}`"
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
          ></FormInput>
        </b-col>
        <b-col cols="12" md="1" class="pr-2">
          <FormInput
              groupId="user-Amount-group"
              id="user-Amount-group-input"
              class="text-prime-gray rounded-2"
              :state="itemPriceError.status"
              :invalidFeedback="`${itemPriceError.message}`"
              label="Unit Price"
              v-model="itemPrice"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="number"
          ></FormInput>
        </b-col>
        <b-col cols="12" md="1" class="pr-2">
          <FormInput
              groupId="user-Quantity-group"
              id="user-Quantity-group-input"
              class="text-prime-gray rounded-2"
              :state="quantityError.status"
              :invalidFeedback="`${quantityError.message}`"
              label="Quantity"
              v-model="quantity"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="number"
          ></FormInput>
        </b-col>
        <b-col cols="12" md="1" class="pr-2">
          <FormInput
              groupId="user-Amount-group"
              id="user-Amount-group-input"
              class="text-prime-gray rounded-2"
              :state="itemAmountError.status"
              :invalidFeedback="`${itemAmountError.message}`"
              v-model="preTaxAmount"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
              :isDisabled="true"
          ></FormInput>
        </b-col>
        <b-col cols="12" md="1" class="pr-2">
          <FormInput
              groupId="user-Amount-group"
              id="user-Amount-group-input"
              class="text-prime-gray rounded-2"
              :state="itemTaxError.status"
              :invalidFeedback="`${itemTaxError.message}`"
              v-model="itemTax"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="number"
          ></FormInput>
        </b-col>
        <!-- Amount -->
        <b-col cols="12" md="1" class="pr-2">
          <FormInput
              groupId="user-Amount-group"
              id="user-Amount-group-input"
              class="text-prime-gray rounded-2"
              :state="itemAmountError.status"
              :invalidFeedback="`${itemAmountError.message}`"
              v-model="amount"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
              :isDisabled="true"
          ></FormInput>
        </b-col>
        <b-col
            class="d-flex flex-column align-items-end justify-content-end"
            cols="12"
            md=""
        >
          <b-button
              variant="transparent"
              class="d-flex flex-row align-items-center justify-content-center mx-0 my-lg-0 px-2 pb-2 shadow-none text-main-green item-plus-btn"
              pill
              @click="AddItem"
          >
            <b-icon
                class="text-main-green"
                icon="plus-circle"
                size="sm"
                aria-label="Plus"
                font-scale="1.8"
            ></b-icon>
          </b-button>
        </b-col>
      </b-row>
    </template>
    <b-row
        class="d-flex flex-row align-items-start justify-content-end w-100"
        no-gutters
    >
      <b-col
          class="d-flex flex-column align-items-end justify-content-center py-2"
          cols="12"
          md="4"
      >
        <!-- add items dropdown -->
        <b-dropdown
            id="dropdown-form"
            ref="dropdown"
            variant="outline-main-green"
            class="mx-0 mx-md-2 my-2 my-md-0 border-0 filter-dropdown"
            toggle-class="d-flex flex-row align-items-center justify-content-center mx-0 my-2 my-lg-0 px-4 py-2  rounded-pill"
            menu-class="w-100 bg-white border border-main-green py-0 filter-dropdown-menu"
            right
            no-caret
            no-flip
            boundary="scrollParent"
            @show="filterDropDownShow = true"
            @hide="filterDropDownShow = false"
        >
          <template v-slot:button-content>
            <span class="d-inline mx-2">Add an item</span>
          </template>
          <b-dropdown-group flush class="rounded-lg">
            <template v-for="(item, index) in itemsList">
              <b-dropdown-item-button
                  button-class="px-2 py-2"
                  :key="index"
                  @click="AddItemToInvoice(index)"
              >
                <b-row
                    class="d-flex flex-row align-items-center justify-content-start"
                    no-gutters
                >
                  <b-col
                      class="d-flex flex-column align-items-start justify-content-center px-0"
                  >
                    <span class="main-text">{{ item.itemName }}</span>
                    <!-- <span class="text-muted sub-text">{{
                      item.Description
                    }}</span> -->
                  </b-col>
                </b-row>
              </b-dropdown-item-button>
            </template>
            <b-dropdown-item-button
                button-class="px-2 py-2 text-main-green"
                @click="createNewItem()"
            >
              <b-icon
                  class="text-main-green mr-1"
                  icon="plus-circle-fill"
                  size="sm"
                  aria-label="Plus"
                  font-scale="1.1"
              ></b-icon>
              Create a new item
            </b-dropdown-item-button
            >
          </b-dropdown-group>
        </b-dropdown>
      </b-col>
    </b-row>
    <div
        class="total row d-flex flex-row align-items-start justify-content-start w-100 no-gutters"
    >
      <!-- <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 "
          no-gutters
          style="text-align: right"
      >
        <b-col
            cols="12"
            md="12"
            class="d-flex flex-column align-items-end justify-content-center py-2"
            lg="12"
            v-if="this.productItems.length != 0"
        >
          <h6 class="tax-text">Tax</h6>
          <FormInput
              groupId="user-tax-group"
              id="user-tax-group-input"
              class="text-prime-gray rounded-2"
              :state="taxError.status"
              :invalidFeedback="`${taxError.message}`"
              label="Tax"
              v-model="tax"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
          ></FormInput>
        </b-col>
      </b-row> -->
      <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 "
      >
        <b-col v-if="this.productItems.length != 0">
          <span class="mr-3 total-price-text">Total</span>
          <b-dropdown
              split
              split-variant="outline-primary"
              variant="main-green"
              :text="choosen_currency"
              class="m-2"
              size="sm"
              dropup
          >
            <b-dropdown-item v-for="currency in currencyList" :key="currency"
                             @click="changeChoosenCurrency(currency)">
              ({{ currency.symbol }}) {{ currency.currency }}
            </b-dropdown-item>
          </b-dropdown>
          <span class="total-price">{{ currency }} {{ this.finalAmount }}</span>
        </b-col>
        <b-col cols="12">
          <span class="mr-3 total-price-conversion-text">{{ conversion_text }}</span>
        </b-col>
      </b-row>
    </div>
  </b-row>
</template>
<script>
// @ is an alias to /src

import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
// import { mapActions } from "vuex";

// services
import {AddItems, SearchItem} from "@/services/items.service";
import {GetAllItems} from "@/services/items.service";
import {GetGlCode} from "@/services/items.service";
import {formatNumbers} from "@/util/formatNumbers.js";
import {GetCurrentWorkspace} from "@/services/settingsbusiness.service";
import {Currencies, GetCurrencyBySymbol} from "@/util/currencies";
import {pairConversion} from "@/services/currency.service";

export default {
  name: "AddItemsQuoteCopy",
  components: {
    FormInput,
    FormSelect,
  },
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    itemContent: {
      type: Array,
    },
    tax: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0.0,
    },
    conversions: {}
  },
  data() {
    return {
      currency: localStorage.getItem("CURRENCY"),
      anchor_currency: localStorage.getItem("CURRENCY"),
      isEditInvoice: false,
      isNewItem: false,
      itemId: null,
      itemName: null,
      itemDescription: null,
      quantity: 0,
      itemPrice: 0.0,
      itemTax: 0.0,
      taxOri: 0.0,
      amount: 0.0,
      preTaxAmount: 0.0,
      totalAmount: null,
      glcode: null,
      uom: null,
      itemNameError: {
        status: null,
        message: "",
      },
      itemDescriptionError: {
        status: null,
        message: "",
      },
      glCodeError: {
        status: null,
        message: "",
      },
      uomError: {
        status: null,
        message: "",
      },
      quantityError: {
        status: null,
        message: "",
      },
      itemPriceError: {
        status: null,
        message: "",
      },
      itemAmountError: {
        status: null,
        message: "",
      },
      itemTaxError: {
        status: null,
        message: "",
      },
      taxError: {
        status: null,
        message: "",
      },
      itemsHeader: [
        {
          key: "itemName",
          label: "Name",
          // class: "text-left pr-5",
        },
        {
          key: "glcode",
          label: "GL Code",
          class: "text-left",
        },
        {
          key: "itemDescription",
          label: "Description ",
          // class: "text-center pr-5",
        },
        {
          key: "itemUnitPrice",
          label: "Unit Price ",
          // class: "text-center pr-5",
        },
        {
          key: "quantity",
          label: "Qty",
          class: "text-right",
        },
        {
          key: "pretaxamount",
          label: "Pre Tax Amount",
          class: "text-center",
        },
        {
          key: "tax",
          label: "Tax",
          class: "text-center",
        },
        {
          key: "amount",
          label: "Amount",
          class: "text-center",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
        },
      ],
      itemsList: [
        {
          itemId: null,
          itemName: null,
          itemDescription: null,
          quantity: 0,
          itemPrice: 0.0,
          glcode: null,
          uom: null,
          actions: null,
          itemAmount: null,
        },
      ],
      productItems: [
        // {
        //   itemDetails: {
        //     title: null,
        //     description: null,
        //   },
        //   quantity: null,
        //   itemPrice: null,
        //   totalAmount: null,
        //   actions: null
        // },
      ],
      // table pagination controls
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      glOptions: [],
      uomOptions: [
        {
          text: "Meter(m)",
          value: "Meter(m)",
        },
        {
          text: "Milimeter(mm)",
          value: "Milimeter(mm)",
        },
        {
          text: "Centimeter(cm)",
          value: "Centimeter(cm)",
        },
        {
          text: "kilometer(km)",
          value: "kilometer(km)",
        },
        {
          text: "Inch(in)",
          value: "Inch(in)",
        },
        {
          text: "Foot(ft)",
          value: "Foot(ft)",
        },
        {
          text: "Yard(yd",
          value: "Yard(yd",
        },
        {
          text: "Square feet(ft2)",
          value: "Square feet(ft2)",
        },
        {
          text: "Cubic meter(m3)",
          value: "Cubic meter(m3)",
        },
        {
          text: "Mililiter(ml)",
          value: "Mililiter(ml)",
        },
        {
          text: "Liter(1)",
          value: "Liter(1)",
        },
        {
          text: "Cubic Inch (in3)",
          value: "Cubic Inch (in3)",
        },
        {
          text: "Gallon(gal)",
          value: "Gallon(gal)",
        },
        {
          text: "Grams(g)",
          value: "Grams(g)",
        },
        {
          text: "Kilograms(kg)",
          value: "Kilograms(kg)",
        },
        {
          text: "Ounce(oz)",
          value: "Ounce(oz)",
        },
        {
          text: "Pound(lb))",
          value: "Pound(lb)",
        },
        {
          text: "Ton(ton)",
          value: "Ton(ton)",
        },
        {
          text: "Pieces(Pcs)",
          value: "Pieces(Pcs)",
        },
        {
          text: "Packs(Pks)",
          value: "Packs(Pks)",
        },
      ],
      currencyList: Currencies,
      choosen_currency: '',
      conversion_text: '',
      conversion_rate: null
    };
  },
  async mounted() {
    await this.initFn();
    this.initCurrentWS();
    this.isEditInvoice = this.$route.meta.isEdit;
    if (this.isEditInvoice) {
      // await this.initEditProductsFn(this.itemContent);
      console.log("edit products child", this.itemContent);
      // console.log(this.$route.params.invoiceId);
      // console.log("this.productItems", this.productItems);
      // this.$emit("getProductItems", this.productItems);
    }
  },
  watch: {
    itemContent(val) {
      console.log("val", val);
      this.productItems = val.map((x) => {
        return {
          itemName: {
            itemId: x._id,
            title: x.name,
          },
          itemDescription: x.description,
          itemUnitPrice: x.price,
          quantity: x.quantity,
          pretaxamount: x.price * x.quantity,
          tax: x.tax,
          amount: x.price * x.quantity + (x.price * x.quantity * x.tax) / 100,
          actions: this.actions,
          glcode: x.glcode,
          uom: this.uom,
          gst: this.Gst,
        };
      });
      // console.log("this.productItems", this.productItems);
      // this.$emit("getProductItems", this.productItems);
    },
    productItems(val) {
      if (val) console.log("this.productItems", this.productItems);
      this.$emit("getProductItems", this.productItems);
    },
    itemName(val) {
      if (val) {
        this.itemNameError.status = true;
        if (val.length >= 18) {
          this.itemNameError.status = false;
          this.itemNameError.message = "Item name should be less than 17 characters";
        }
      } else {
        this.itemNameError.status = false;
        this.itemNameError.message = "fill in the item name";
      }
    },
    itemDescription(val) {
      if (val) {
        this.itemDescriptionError.status = true;
        if (val.length >= 50) {
          this.itemDescriptionError.status = false;
          this.itemDescriptionError.message = "Item description should be less than 50 characters";
        }
      } else {
        this.itemDescriptionError.status = false;
        this.itemDescriptionError.message = "fill in the item name";
      }
    },
    glcode(val) {
      if (val) {
        this.glCodeError.status = true;
      } else {
        this.glCodeError.status = false;
        this.glCodeError.message = "fill in the GL Code";
      }
    },
    // uom(val) {
    //   if (val) {
    //     this.uomError.status = true;
    //   } else {
    //     this.uomError.status = false;
    //     this.uomError.message = "fill in the UoM";
    //   }
    // },
    quantity(val) {
      if (val) {
        this.quantityError.status = true;
        this.preTaxAmount = Number(val) * this.itemPrice;
      } else {
        this.quantityError.status = false;
        this.quantityError.message = "fill in the quantity";
      }
    },
    itemPrice(val) {
      if (val) {
        this.itemPriceError.status = true;
        if (this.quantity) {
          this.preTaxAmount = this.quantity * Number(val);
        }
      } else {
        this.itemPriceError.status = false;
        this.itemPriceError.message = "fill in the unit price";
      }
    },
    itemTax(val) {
      if (val || val === 0) {
        this.itemTaxError.status = true;
        this.amount =
            this.preTaxAmount + (this.preTaxAmount * Number(val)) / 100;
      } else {
        this.itemTaxError.status = false;
        this.itemTaxError.message = `fill in the ${this.taxLabel}`;
      }
    },
    preTaxAmount(val) {
      if (val) {
        this.amount = Number(val) + (this.itemTax * Number(val)) / 100;
      }
    },
    // tax(val) {
    //   if (val) {
    //     this.$emit("updateTax", parseFloat(val).toFixed(2));
    //     this.taxError.status = true;
    //   } else {
    //     this.taxError.status = false;
    //     this.taxError.message = "fill in the tax";
    //   }
    // },
    currency(val) {
      this.choosen_currency = GetCurrencyBySymbol(val.toString().trim());
    },
    conversions(val) {
      if (val.currency) {
        this.currency = val.currency;
      }
      console.log(val)
    }
  },
  computed: {
    itemAmount() {
      return (this.itemPrice * this.quantity).toLocaleString();
    },
    finalAmount() {
      let totalAmountTax = this.productItems.reduce(function (total, item) {
        return total + item.amount;
      }, 0);
      this.$emit("total_amount", totalAmountTax);
      if (this.anchor_currency !== this.currency) {
        this.conversion();
      }
      return this.numberFormat(totalAmountTax);
    },
  },
  methods: {
    async initCurrentWS() {
      let {data} = await GetCurrentWorkspace();
      this.currency = data.currency;
      this.choosen_currency = GetCurrencyBySymbol(this.currency.toString().trim().toUpperCase());
      this.itemTax = data.vat_rate;
      this.taxOri = data.vat_rate;
    },
    numberFormat(val) {
      return formatNumbers(val);
    },
    async editItem(item) {
      this.removeItem(item);
      this.isNewItem = true;
      this.itemId = item.item.itemName.itemId;
      this.itemName = item.item.itemName.title;
      this.itemDescription = item.item.itemDescription;
      this.itemPrice = item.item.itemUnitPrice;
      this.quantity = item.item.quantity;
      this.totalAmount = ((item.item.itemUnitPrice * item.item.quantity)*(item.item.tax/100))+item.item.itemUnitPrice * item.item.quantity;
      this.amount = ((item.item.itemUnitPrice * item.item.quantity)*(item.item.tax/100))+item.item.itemUnitPrice * item.item.quantity;
      this.itemTax = +item.item.tax;
      this.glcode = item.item.glcode;
      this.uom = "";
    },
    // Get Items to the Button Dropdown list
    async initFn() {
      try {
        let {data} = await GetAllItems();
        console.log("get all items", data);
        this.itemsList = data.results.results.map((x) => {
          return {
            itemId: x._id,
            itemName: x.name,
            itemDescription: x.description,
            itemUnitPrice: x.itemPrice,
            quantity: x.quantity,
            itemPrice: x.price,
            actions: x,
            glcode: x.glcode,
            uom: x.uom,
          };
        });
        console.log(data);
      } catch (error) {
        console.log(error);
      }
      try {
        let {data} = await GetGlCode();
          //Fixed GLcode Undefined
        let arr=[]
        for(let i=0;i<data.length;i++){
          if(data[i].startsWith('undefined')){
            continue
          }
          arr.push(data[i])

        }
        this.glOptions = arr
        // this.glOptions = data;
      } catch (error) {
        console.log("GL Error" + error);
      }
    },
    // Add selected Items from the dropdown to Form Inputs
    AddItemToInvoice(index) {
      this.isNewItem = true;
      this.itemId = this.itemsList[index].itemId;
      this.itemName = this.itemsList[index].itemName;
      this.itemDescription = this.itemsList[index].itemDescription;
      this.quantity = this.itemsList[index].quantity;
      this.itemPrice = this.itemsList[index].itemPrice;
      this.glcode = this.itemsList[index].glcode;
      this.uom = this.itemsList[index].uom;
    },
    // get edit product items to table
    async initEditProductsFn(val) {
      console.log("val", val);
      this.productItems = val.map((x) => {
        return {
          itemDetails: {
            itemId: x._id,
            title: x.name,
            description: x.description,
          },
          itemPrice: this.itemPrice,
          quantity: x.quantity,
          // itemPrice: x.price,
          totalAmount: this.quantity * this.itemPrice,
          actions: this.actions,
        };
      });
    },
    // Add Form Input data to the table
    async AddItem() {
      if (this.itemName && this.quantity && this.itemNameError.status && this.itemDescriptionError.status && this.glCodeError.status && this.itemPriceError.status) {
        this.isNewItem = false;
        let payload = {
          name: this.itemName,
          price: this.itemPrice,
          uom: this.uom,
          glcode: this.glcode,
          tax_per_item: +this.itemTax,
        };
        console.log(payload);
        try {
          if (!(await this.checkDuplicate())) {
            let {data} = await AddItems(payload);
            this.itemId = data.savedItem._id;
          }
          let newItem = {
            itemName: {
              itemId: this.itemId,
              title: this.itemName,
            },
            itemDescription: this.itemDescription,
            itemUnitPrice: this.itemPrice,
            quantity: Number(this.quantity),
            pretaxamount: this.preTaxAmount,
            tax: +this.itemTax,
            amount: this.amount,
            actions: this.actions,
            glcode: this.glcode,
            uom: this.uom,
          };
          this.productItems.push(newItem);
          this.initFn();
        } catch (error) {
          console.log(error);
        }
      } else {
        this.errorFn();
      }
      let {data} = await GetCurrentWorkspace();
      this.itemTax = data.vat_rate
    },
    errorFn() {
      if (!this.itemName) {
        this.itemNameError.status = false;
        this.itemNameError.message = "Fill in the item name";
      }
      if (!this.quantity) {
        this.quantityError.status = false;
        this.quantityError.message = "Fill in the quantity";
      }
      if (!this.itemPrice) {
        this.itemPriceError.status = false;
        this.itemPriceError.message = "Fill in the item price";
      }
      if (!this.glcode) {
        this.glCodeError.status = false;
        this.glCodeError.message = "Fill in the GL Code";
      }
      if (!this.uom) {
        this.uomError.status = false;
        this.uomError.message = "Fill in the UoM";
      }
      if (!this.itemAmount) {
        this.itemAmountError.status = false;
        this.itemAmountError.message = "Fill in the total amount";
      }
      if (!this.tax) {
        this.taxError.status = false;
        this.taxError.message = "Fill in the tax";
      }
    },
    // Remove Item from the table
    removeItem(data) {
      console.log("remove", data.index);
      let removedItem = this.productItems.splice(data.index, 1);
      console.log("remainItems", removedItem);
      console.log("productItems", this.productItems);
      this.$emit("getProductItems", this.productItems);
    },
    async createNewItem() {
      this.isNewItem = true;
      this.itemId = "";
      this.itemName = "";
      this.itemUnitPrice = "";
      this.itemDescription = "";
      this.quantity = "";
      this.itemPrice = "";
      this.glcode = "";
      this.uom = "";
    },
    async checkDuplicate() {
      const {data} = await SearchItem({
        text: this.itemName,
        full_name: true,
      });
      if (data.data.length > 0) {
        this.itemId = data.data[0]._id;
      }
      return data.data.length > 0;
    },
    async changeChoosenCurrency(currency) {
      this.conversion_rate = '';
      this.choosen_currency = '(' + currency.symbol + ') ' + currency.currency;
      this.currency = currency.symbol;
      await this.conversion();
    },
    async conversion() {
      const res = await pairConversion({base: this.anchor_currency, target: this.currency});
      this.conversion_rate = res.data.conversion_rate;
      const convertedTotal = this.finalAmount / this.conversion_rate;
      this.conversion_text = 'Currency conversion ' + convertedTotal.toFixed(2) + '(' + this.anchor_currency + ')' + ' at ' + res.data.conversion_rate;
      this.$emit("conversion_data",
          {
            rate: this.conversion_rate,
            anchor_currency: this.anchor_currency,
            currency: this.currency
          }
      );
    }
  },
};
</script>
<style scoped>
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 800px;
}

/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
}

.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}

.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: #0b9b7d;
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}

/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  color: #0b9b7d;
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  background-color: #0b9b7d;
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}

/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 35px;
  height: 35px;
  padding: 0;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .delete-btn > .delete-icon {
  width: 16px;
  height: 16px;
  /* margin-bottom: 6px;
  margin-right: 14px; */
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  background-color: var(--danger);
}

.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  color: var(--white);
}

/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}

/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}

.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}

.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}

/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}

.table-pagination >>> .custom-page-item button:hover {
  color: #0b9b7d;
  border-color: #0b9b7d;
  background-color: var(--light);
}

.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}

.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}

.filter-dropdown {
  /* height: 50px; */
  min-width: 100px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.filter-dropdown >>> .item-button {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  max-height: 110px;
  min-width: 950px;
  /* min-height: 12vh; */
  /* overflow-x: hidden; */
  overflow: visible;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.filter-dropdown >>> .filter-dropdown-menu button.dropdown-item.active,
.filter-dropdown >>> .filter-dropdown-menu button.dropdown-item:active {
  background-color: var(--light);
}

.tax-text {
  font-size: 0.9rem;
  color: var(--prime-gray);
}

.total {
  text-align: right;
  margin-top: 100px;
}

.total-price-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  color: #222831;
}

.total-price {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 36px;
  color: #222831;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  /* .table-card-row .search-input-group {
    max-width: none;
  } */
  .filter-dropdown >>> .filter-dropdown-menu {
    height: auto;
    max-height: 110px;

    min-width: 300px;
    /* min-height: 12vh; */
    /* overflow-x: hidden; */
    overflow: visible;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
  }
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  color: #0b9b7d;
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  background-color: #0b9b7d;
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}

.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn {
  width: 35px;
  height: 35px;
  padding: 0;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  width: 16px;
  height: 16px;
  /* margin-bottom: 6px;
  margin-right: 14px; */
  color: #0b9b7d;
  transition: color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn:hover {
  background-color: #0b9b7d;
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}
</style>
