<template>
  <!-- order items modal -->
  <b-modal
    id="add-supplier-modal"
    ref="AddSupplierModal"
    title="Add a Supplier"
    size="md"
    header-class="font-primary"
    header-bg-variant="light"
    header-text-variant="main-green"
    header-border-variant="white"
    footer-bg-variant="light"
    footer-border-variant="white"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end mx-0 w-100"
      >
        <b-button
          variant="gray-550"
          pill
          class="my-2 my-sm-0 mr-0 mr-sm-2 border-0 bg-ghost-white action-btn"
          @click="cancel"
        >
          Cancel
        </b-button>
        <b-button
          variant="main-green"
          pill
          class="my-2 my-sm-0 ml-0 ml-sm-2 border-0 action-btn-save"
          @click="addCustomer()"
        >
          Save
        </b-button>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <b-icon
        icon="x-circle"
        class="text-dark"
        @click="closeModal()"
        aria-label="Close Modal"
        font-scale="0.8"
      ></b-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 my-3 my-md-2 px-1 py-1"
      no-gutters
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-main-green"
        class="customer-tabs w-100"
        nav-class="tab-wrapper"
        content-class="my-3"
      >
        <b-tab
          title="Contact"
          title-link-class="tab-nav-link"
          :active="selectedTab === 1"
          @click="selectedTab = 1"
        >
          <!-- full row input (Supplier) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 pt-4 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Supplier</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="customeruser-name-group"
                id="customeruser-name-group-input"
                class="text-prime-gray rounded-2"
                :state="customerNameError.status"
                :invalidFeedback="`${customerNameError.message}`"
                label="Business or a person"
                v-model="customerName"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <b-row
          
            class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span
                class="text-left text-dark font-primary font-weight-normal"
                >Supplier type</span
              >
            </b-col>
            <b-col cols="8">
              <FormSelect
                groupId="Supplier-s-group"
                id="Supplier-s-group-input"
                class="text-prime-gray rounded-2"
                label="Supplier type"
                v-model="SuplierType"
                invalidFeedback="Please fill out this field"
                :whiteBG="false"
                :options="SupplierTypeList"
                form="login-form"
              ></FormSelect>
            </b-col>
          </b-row>
          <!-- full row input (Full name) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Full name</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="fullNameuser-name-group"
                id="fullNameuser-name-group-input"
                class="text-prime-gray rounded-2"
                label="Full name"
                v-model="fullName"
                :state="fullNameError.status"
                :invalidFeedback="fullNameError.message"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (Phone) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Phone</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="Phoneuser-name-group"
                id="Phoneuser-name-group-input"
                class="text-prime-gray rounded-2"
                label="Phone"
                v-model="customerPhone"
                :state="customerPhoneError.status"
                :invalidFeedback="customerPhoneError.message"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (E-mail) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >E-mail</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="Emailuser-name-group"
                id="Emailuser-name-group-input"
                class="text-prime-gray rounded-2"
                :state="customerEmailError.status"
                :invalidFeedback="`${customerEmailError.message}`"
                label="E-mail"
                v-model="customerEmail"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
            <b-row
           
            class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span
                class="text-left text-dark font-primary font-weight-normal"
                >Payment term</span
              >
            </b-col>
            <b-col cols="8">
              <FormSelect
                groupId="Payment-s-group"
                id="Payment-s-group-input"
                class="text-prime-gray rounded-2"
                label="Payment term"
                v-model="PaymentTerm"
                invalidFeedback="Please fill out this field"
                :whiteBG="false"
                :options="PaymentTermList"
                form="login-form"
              ></FormSelect>
            </b-col>
          </b-row>
        </b-row>

        <b-row
          class="d-flex flex-row align-items-center justify-content-between w-75 my-1 my-md-2 pl-4 py-2"
          no-gutters
        >
          </b-row>
        </b-tab>
        <b-tab
          title="Billing"
          title-link-class="tab-nav-link"
          :active="selectedTab === 2"
          @click="selectedTab = 2"
        >
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 pt-4  px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Country</span
              >
            </b-col>
            <b-col cols="8">
              <FormSelect
                groupId="industry-group"
                id="industry-group-input"
                class="text-prime-gray rounded-2"
                label="Choose a country"
                v-model="billingCountry"
                :state="
                  errorOnTab2 && billingAddress.addressCountry != null
                    ? billingAddress.addressCountry == 0
                      ? false
                      : true
                    : null
                "
                invalidFeedback="Please fill out this field"
                :whiteBG="false"
                :options="countryList"
                form="login-form"
              ></FormSelect>
            </b-col>
          </b-row>
          <!-- full row input (No :) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >No :</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="Numberuser-name-group"
                id="Numberuser-name-group-input"
                class="text-prime-gray rounded-2"
                label="Number"
                v-model="billingAddress.addressNum"
                :state="
                  errorOnTab2 && billingAddress.addressNum != null
                    ? billingAddress.addressNum == 0
                      ? false
                      : true
                    : null
                "
                invalidFeedback="Please fill out this field"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (Street) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Street</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="Streetuser-name-group"
                id="Streetuser-name-group-input"
                class="text-prime-gray rounded-2"
                label="Street"
                v-model="billingAddress.addressStreet"
                :state="
                  errorOnTab2 && billingAddress.addressStreet != null
                    ? billingAddress.addressStreet == 0
                      ? false
                      : true
                    : null
                "
                invalidFeedback="Please fill out this field"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (City) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >City</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="Cityuser-name-group"
                id="Cityuser-name-group-input"
                class="text-prime-gray rounded-2"
                label="City"
                v-model="billingAddress.addressCity"
                :state="
                  errorOnTab2 && billingAddress.addressCity != null
                    ? billingAddress.addressCity == 0
                      ? false
                      : true
                    : null
                "
                invalidFeedback="Please fill out this field"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (Zip Code) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Zip Code</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="Zipuser-name-group"
                id="Zipuser-name-group-input"
                class="text-prime-gray rounded-2"
                label="Zip Code"
                v-model="billingAddress.addressZip"
                :state="
                  errorOnTab2 && billingAddress.addressZip != null
                    ? billingAddress.addressZip == 0
                      ? false
                      : true
                    : null
                "
                invalidFeedback="Please fill out this field"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="number"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (Province) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Province</span
              >
            </b-col>
            <b-col cols="8">
              <FormSelect
                groupId="industry-group"
                id="industry-group-input"
                class="text-prime-gray rounded-2 mt-2"
                label="Choose a province"
                v-model="billingAddress.addressProvince"
                :state="
                  errorOnTab2 && billingAddress.addressProvince != null
                    ? billingAddress.addressProvince == 0
                      ? false
                      : true
                    : null
                "
                invalidFeedback="Please fill out this field"
                :whiteBG="false"
                :options="filteredProvinceListBilling"
                :filtered-options="filteredProvinceListBilling"
                form="login-form"
              ></FormSelect>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          title="Shipping"
          title-link-class="tab-nav-link"
          :active="selectedTab === 3"
          @click="selectedTab = 3"
        >
          <!-- shipping address checkbox  -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 px-2 py-4"
            no-gutters
          >
            <b-col
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <b-form-checkbox
                id="checkbox-1"
                v-model="shippingStatus"
                name="checkbox-1"
                value="isBilling"
                unchecked-value="isShipping"
              >
                Same as billing address
              </b-form-checkbox>
            </b-col>
          </b-row>
          <!-- shipping address form  -->
          <template v-if="this.shippingStatus == `isShipping`">
            <!-- full row input (Full name) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2 pt-4"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Full name</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray rounded-2"
                  label="Full name"
                  v-model="shippingName"
                  :state="shippingNameError.status"
                  :invalidFeedback="shippingNameError.message"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (Phone) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Phone</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray rounded-2"
                  label="Phone"
                  v-model="shippingPhone"
                  :state="shippingPhoneError.status"
                  :invalidFeedback="shippingPhoneError.message"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (E-mail) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >E-mail</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray rounded-2"
                  :state="shippingEmailError.status"
                  :invalidFeedback="`${shippingEmailError.message}`"
                  label="E-mail"
                  v-model="shippingEmail"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
            </b-row>

            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 pt-4 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Country</span
                >
              </b-col>
              <b-col cols="8">
                <FormSelect
                  groupId="industry-group"
                  id="industry-group-input"
                  class="text-prime-gray rounded-2"
                  label="Choose a country"
                  v-model="shippingCountry"
                  :state="
                    errorOnTab2 && shippingAddress.addressCountry != null
                      ? shippingAddress.addressCountry == 0
                        ? false
                        : true
                      : null
                  "
                  invalidFeedback="Please fill out this field"
                  :whiteBG="false"
                  :options="countryList"
                  form="login-form"
                ></FormSelect>
              </b-col>
            </b-row>
            <!-- full row input (No :) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >No :</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray rounded-2"
                  label="Number"
                  v-model="shippingAddress.addressNum"
                  :state="
                    shippingAddress.addressNum != null
                      ? shippingAddress.addressNum == 0
                        ? false
                        : true
                      : null
                  "
                  invalidFeedback="Please fill out this field"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (Street) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Street</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray rounded-2"
                  label="Street"
                  v-model="shippingAddress.addressStreet"
                  :state="
                    shippingAddress.addressStreet != null
                      ? shippingAddress.addressStreet == 0
                        ? false
                        : true
                      : null
                  "
                  invalidFeedback="Please fill out this field"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (City) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >City</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray rounded-2"
                  label="City"
                  v-model="shippingAddress.addressCity"
                  :state="
                    shippingAddress.addressCity != null
                      ? shippingAddress.addressCity == 0
                        ? false
                        : true
                      : null
                  "
                  invalidFeedback="Please fill out this field"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (Zip Code) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Zip Code</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray rounded-2"
                  label="Zip Code"
                  v-model="shippingAddress.addressZip"
                  :state="
                    shippingAddress.addressZip != null
                      ? shippingAddress.addressZip == 0
                        ? false
                        : true
                      : null
                  "
                  invalidFeedback="Please fill out this field"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="number"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (Province) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Province</span
                >
              </b-col>
              <b-col cols="8">
                <FormSelect
                  groupId="industry-group"
                  id="industry-group-input"
                  class="text-prime-gray rounded-2 mt-2"
                  label="Choose a province"
                  v-model="shippingAddress.addressProvince"
                  :state="
                    errorOnTab2 && shippingAddress.addressCountry != null
                      ? shippingAddress.addressCountry == 0
                        ? false
                        : true
                      : null
                  "
                  invalidFeedback="Please fill out this field"
                  :whiteBG="false"
                  :options="filteredProvinceListShipping"
                  form="login-form"
                ></FormSelect>
              </b-col>
            </b-row>
          </template>
        </b-tab>
      </b-tabs>
    </b-row>
  </b-modal>
</template>

<script>
// components
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import CheckEmail from "@/util/CheckEmail";

import isValidContactNumber from "@/util/isValidContactNumber";

// services
import {
  AddSupplier,
  GetSingleSupplier,
  UpdateSupplier,
} from "@/services/customers.service";

export default {
  name: "AddSupplierModal",
  props: {
    itemContent: {
      default: null,
    },
    clear: {
      default: false,
    },
  },
  components: {
    FormInput,
    FormSelect,
  },
  data() {
    return {
      isEdit: null,
      customerName: null,
      SuplierType: null,
      PaymentTerm: null,
      fullName: null,
      customerPhone: null,
      customerEmail: null,
      countryList: ["New Zealand", "Australia"],
      billingCountry: null,
      shippingCountry: null,

      billingAddress: {
        addressNum: null,
        addressStreet: null,
        addressCity: null,
        addressZip: null,
        addressProvince: null,
        addressCountry: null,
      },
      shippingAddress: {
        addressNum: null,
        addressStreet: null,
        addressCity: null,
        addressZip: null,
        addressProvince: null,
        addressCountry: null,
      },
      shippingName: null,
      shippingPhone: null,
      shippingEmail: null,
      shippingStatus: "isBilling",
      SupplierTypeList: [
      "General",
      "Employee",
      "Contractor",
      "Preferred",
      "International",
      "One-off",
      "Tax",
      "Grants",
      "Other",
    ],
    PaymentTermList: [
      "7 days",
      "20 FOLM",
      "30 FOLM",
      "Same day",
      "Open",
      "N/A",
    ],
      NewzelandProvinceList: [
        "Northland",
        "Auckland",
        "Waikato",
        "Bay of Plenty",
        "Gisborne",
        "Hawke's Bay",
        "Taranaki",
        "Manawatu-Whanganui",
        "Wellington",
        "Tasman",
        "Nelson",
        "Marlborough",
        "West Coast",
        "Canterbury",
        "Otago",
        "Southland",
      ],
      AusiProvinceList: [
        "New South Wales",
        "Queensland",
        "Northern Territory",
        "Western Australia",
        "South Australia",
        "Victoria",
        "Australian Capital Territory",
        "Tasmania",
      ],
      fijiProvinceList: [
        "Ba",
        "Bua",
        "Cakaudrove",
        "Kadavu",
        "Lau",
        "Lomaiviti",
        "Macuata",
        "Nadroga-Navosa",
        "Naitasiri",
        "Namosi",
        "Ra",
        "Rewa",
        "Serua",
        "Tailevu",
      ],
      // addressCountryOptions: ["Sri Lanka"],
      customerNameError: {
        status: null,
        message: "",
      },
      customerEmailError: {
        status: null,
        message: "",
      },
      shippingEmailError: {
        status: null,
        message: "",
      },
      fullNameError: {
        status: null,
        message: "",
      },
      customerPhoneError: {
        status: null,
        message: "",
      },
      shippingPhoneError: {
        status: null,
        message: "",
      },
      shippingNameError: {
        status: null,
        message: "",
      },

      addressNumError: {
        status: null,
        message: "",
      },
      selectedTab: 1,
      errorOnTab2: false,
    };
  },
  watch: {
    itemContent(val) {
      console.log("🚀 ~ file: AddSupplierModal.vue:914 ~ itemContent ~ val:", val)
      console.log("item content", val);
      if (val) {
        this.isEdit = true;
        this.initEditFn(val);
      } else {
        this.isEdit = false;
        this.clearFn();
      }
    },
    customerName(value) {
      if (value) {
        this.customerNameError.status = true;
      } else {
        this.customerNameError.status = false;
        this.customerNameError.message = "Please fill out this field";
      }
    },
    fullName(value) {
      if (value) {
        this.fullNameError.status = true;
      } else {
        this.fullNameError.status = false;
        this.fullNameError.message = "Please fill out this field";
      }
    },
    customerPhone(value) {
      this.validatePhoneNumber(value);
    },
    customerEmail(value) {
      if (value) {
        if (CheckEmail(value) == true) this.customerEmailError.status = true;
        else {
          this.customerEmailError.status = false;
          this.customerEmailError.message =
            "Please enter a valid email address";
        }
      } else {
        this.customerEmailError.status = true;
        // this.customerEmailError.message = "Please fill in your email address";
      }
    },
    shippingName(value) {
      if (value) {
        this.shippingNameError.status = true;
      } else {
        this.shippingNameError.status = false;
        this.shippingNameError.message = "Please fill out this field";
      }
    },
    shippingPhone(value) {
      this.validateShippingPhoneNumber(value);
    },
    shippingEmail(value) {
      if (value) {
        if (CheckEmail(value) == true) this.shippingEmailError.status = true;
        else {
          this.shippingEmailError.status = false;
          this.shippingEmailError.message =
            "Please enter a valid email address";
        }
      } else {
        this.shippingEmailError.status = true;
        // this.shippingEmailError.message = "Please fill in your email address";
      }
    },
    billingCountry(value) {
      if (this.shippingStatus === "isBilling") {
        this.shippingCountry = value;
        console.log(
          "🚀 ~ file: AddSupplierModal.vue:905 ~ billingCountry ~ this.shippingCountry:",
          this.shippingCountry
        );
        console.log("inside");
      }
      console.log("billing address", value);
      this.billingAddress.addressCountry = value;
      this.validatePhoneNumber(this.customerPhone, value);
    },
    shippingCountry(value) {
      console.log("shipping address", value);
      this.shippingAddress.addressCountry = value;
      this.validateShippingPhoneNumber(this.shippingPhone, value);
    },
  },
  mounted() {
    if(this.itemContent){
      this.isEdit =true
    }
    if (this.itemContent !== null) {
      this.initEditFn(this.itemContent);
    } else {
      this.clearFn();
    }
    this.shippingStatus = "isBilling";
  },
  computed: {
    filteredProvinceListBilling() {
      let country = this.billingAddress.addressCountry || this.billingCountry;
      if (country === "New Zealand") {
        return this.NewzelandProvinceList;
      } else if (country === "Australia") {
        return this.AusiProvinceList;
      } else if (country === "Fiji") {
        return this.fijiProvinceList;
      } else {
        // return [];
        return this.NewzelandProvinceList;
      }
    },
    filteredProvinceListShipping() {
      let country = this.shippingAddress.addressCountry || this.shippingCountry;
      if (country === "New Zealand") {
        return this.NewzelandProvinceList;
      } else if (country === "Australia") {
        return this.AusiProvinceList;
      } else if (country === "Fiji") {
        return this.fijiProvinceList;
      } else {
        return [];
      }
    },
  },
  methods: {
    clearFn() {
      console.log("🚀 ~ file: AddSupplierModal.vue:1035 ~ clearFn ~ clearFn:")
      this.isEdit = null;
      this.customerName = null;
      this.fullName = null;
      this.customerPhone = null;
      this.customerEmail = null;
      this.PaymentTerm= null,
      this.SuplierType= null,
      this.countryList = ["New Zealand", "Australia", "Fiji"];
      this.billingCountry = null;
      this.shippingCountry = null;
      this.billingAddress = {
        addressNum: null,
        addressStreet: null,
        addressCity: null,
        addressZip: null,
        addressProvince: null,
        addressCountry: null,
      };
      this.shippingAddress = {
        addressNum: null,
        addressStreet: null,
        addressCity: null,
        addressZip: null,
        addressProvince: null,
        addressCountry: null,
      };
      this.shippingName = null;
      this.shippingPhone = null;
      this.shippingEmail = null;
      this.shippingStatus = null;
    },
    validatePhoneNumber(value) {
      this.customerPhoneError.status = null;
      if (!value) {
        this.customerPhoneError.status = false;
        this.customerPhoneError.message = "Please fill in the contact number";
      } else {
        let ans = isValidContactNumber(
          value,
          this.billingAddress.addressCountry
        );
        if (ans === true) {
          this.customerPhoneError.status = true;
        } else {
          this.customerPhoneError.status = false;
          this.customerPhoneError.message = "Invalid contact number";
        }
      }
    },
    validateShippingPhoneNumber(value) {
      this.shippingPhoneError.status = null;
      if (!value) {
        this.shippingPhoneError.status = false;
        this.shippingPhoneError.message = "Please fill in the contact number";
      } else if (value && this.shippingAddress.addressCountry) {
        let ans = isValidContactNumber(
          value,
          this.shippingAddress.addressCountry
        );
        if (ans == true) {
          this.shippingPhoneError.status = true;
        } else {
          this.shippingPhoneError.status = false;
          this.shippingPhoneError.message = "Invalid contact number";
        }
      }
    },
    closeModal() {
      this.$emit("onClose");
    },
    async addCustomer() {  
      console.log("🚀 ~ file: AddSupplierModal.vue:1107 ~ addCustomer ~ this.isEdit:", this.isEdit)
      console.log("clicked");
      let ans1 = isValidContactNumber(
        this.customerPhone,
        this.billingAddress.addressCountry
      );
      if (
        this.customerName &&
        this.fullName &&
        this.customerPhone &&
        this.customerEmail &&
        this.billingAddress &&
        this.billingAddress.addressNum &&
        this.billingAddress.addressStreet &&
        this.billingAddress.addressCity &&
        this.billingAddress.addressZip &&
        this.billingAddress.addressProvince &&
        this.billingAddress.addressCountry &&
        ans1
      ) {
        if (this.isEdit) {
          console.log("item content2", this.itemContent);
          let payload = {
            id: this.itemContent,
            name: this.customerName,
            full_name: this.fullName,
            email: this.customerEmail,
            phone: this.customerPhone,
            Supplier_type:this.SuplierType,
            Payment_term:this.PaymentTerm,
            billing_address: {
              number: this.billingAddress.addressNum,
              street: this.billingAddress.addressStreet,
              city: this.billingAddress.addressCity,
              zipcode: this.billingAddress.addressZip,
              province: this.billingAddress.addressProvince,
              country: this.billingAddress.addressCountry,
            },
            shipping_address: {
              number: this.shippingAddress.addressNum,
              street: this.shippingAddress.addressStreet,
              city: this.shippingAddress.addressCity,
              zipcode: this.shippingAddress.addressZip,
              province: this.shippingAddress.addressProvince,
              country: this.shippingAddress.addressCountry,
            },
            shipping_name: this.shippingName,
            shipping_phone: this.shippingPhone,
            shipping_email: this.shippingEmail,
          };
          try {
            let { data } = await UpdateSupplier(payload);
            console.log("result", data.savedEntity);
            this.$emit("reloadFn", data.savedEntity);
          } catch (error) {
            console.log(error);
          }
        } else {
          let payload1 = {
            name: this.customerName,
            full_name: this.fullName,
            email: this.customerEmail,
            phone: this.customerPhone,
            Supplier_type:this.SuplierType,
            Payment_term:this.PaymentTerm,
            billing_address: {
              number: this.billingAddress.addressNum,
              street: this.billingAddress.addressStreet,
              city: this.billingAddress.addressCity,
              zipcode: this.billingAddress.addressZip,
              province: this.billingAddress.addressProvince,
              country: this.billingAddress.addressCountry,
            },
            shipping_address: {
              number: this.shippingAddress.addressNum,
              street: this.shippingAddress.addressStreet,
              city: this.shippingAddress.addressCity,
              zipcode: this.shippingAddress.addressZip,
              province: this.shippingAddress.addressProvince,
              country: this.shippingAddress.addressCountry,
            },
            shipping_name: this.shippingName,
            shipping_phone: this.shippingPhone,
            shipping_email: this.shippingEmail,
          };
          let payload2 = {
            name: this.customerName,
            full_name: this.fullName,
            email: this.customerEmail,
            phone: this.customerPhone,
            Supplier_type:this.SuplierType,
          Payment_term:this.PaymentTerm,
            billing_address: {
              number: this.billingAddress.addressNum,
              street: this.billingAddress.addressStreet,
              city: this.billingAddress.addressCity,
              zipcode: this.billingAddress.addressZip,
              province: this.billingAddress.addressProvince,
              country: this.billingAddress.addressCountry,
            },
            shipping_address: {
              number: this.billingAddress.addressNum,
              street: this.billingAddress.addressStreet,
              city: this.billingAddress.addressCity,
              zipcode: this.billingAddress.addressZip,
              province: this.billingAddress.addressProvince,
              country: this.shippingAddress.addressCountry,
            },
            shipping_name: this.fullName,
            shipping_phone: this.customerPhone,
            shipping_email: this.customerEmail,
          };
          let payload;
          if (this.shippingStatus == "isShipping") {
            payload = payload1;
          } else {
            payload = payload2;
          }
          console.log("create payload", payload);
          try {
            let { data } = await AddSupplier(payload);
            console.log(
              "🚀 ~ file: AddSupplierModal.vue:1159 ~ AddSupplier ~ data:",
              data
            );
            console.log("result", data.result);
            this.$emit("reloadFn", data.result);
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        this.validateForm();
      }
    },
    validateForm() {
      let isFirstClear = true;
      if (!this.customerName) {
        this.customerNameError.status = false;
        this.customerNameError.message = "Please fill out this field";
        isFirstClear = false;
      }
      if (!this.fullName) {
        this.fullNameError.status = false;
        this.fullNameError.message = "Please fill out this field";
        isFirstClear = false;
      }
      if (!this.customerPhone) {
        this.customerPhoneError.status = false;
        this.customerPhoneError.message = "Please fill out this field";
        isFirstClear = false;
      }
      if (!this.customerEmail) {
        this.customerEmailError.status = false;
        this.customerEmailError.message = "Please fill out this field";
        isFirstClear = false;
      }
      let ans1 = isValidContactNumber(
        this.customerPhone,
        this.billingAddress.addressCountry
      );
      let ans2 = isValidContactNumber(
        this.shippingPhone,
        this.shippingAddress.addressCountry
      );
      if (!ans1 || !ans2) {
        this.customerPhoneError.status = false;
        this.customerPhoneError.message = "Invalid contact number";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to save the Customer",
          content: "Contact number does not match with the country",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
        isFirstClear = false;
      }
      if (!isFirstClear) {
        this.selectedTab = 1;
      }
      if (
        !this.billingAddress ||
        !this.billingAddress.addressCity ||
        !this.billingAddress.addressCountry ||
        !this.billingAddress.addressNum ||
        !this.billingAddress.addressZip ||
        !this.billingAddress.addressStreet ||
        !this.billingAddress.addressProvince
      ) {
        this.errorOnTab2 = true;
        if (isFirstClear) {
          this.selectedTab = 2;
        }
      } else {
        this.errorOnTab2 = false;
      }
    },
    async initEditFn(val) {
      try {
        // console.log("val", val[0].id);
        let { data } = await GetSingleSupplier(val);
        console.log("get single", data);
        (this.customerName = data.name),
          (this.fullName = data.full_name),
          (this.customerEmail = data.email),
          (this.customerPhone = data.phone),
          (this.SuplierType = data.Supplier_type),
        (this.PaymentTerm = data.Payment_term),
          (this.billingAddress.addressNum = data.billing_address.number),
          (this.billingAddress.addressStreet = data.billing_address.street),
          (this.billingAddress.addressCity = data.billing_address.city),
          (this.billingAddress.addressZip = data.billing_address.zipcode),
          (this.billingAddress.addressProvince = data.billing_address.province),
          (this.billingAddress.addressCountry = data.billing_address.country),
          (this.billingCountry = data.billing_address.country),
          (this.shippingAddress.addressNum = data.shipping_address.number),
          (this.shippingAddress.addressStreet = data.shipping_address.street),
          (this.shippingAddress.addressCity = data.shipping_address.city),
          (this.shippingAddress.addressZip = data.shipping_address.zipcode),
          (this.shippingAddress.addressProvince =
            data.shipping_address.province),
          (this.shippingAddress.addressCountry = data.shipping_address.country),
          (this.shippingCountry = data.shipping_address.country),
          (this.shippingName = data.shipping_name),
          (this.shippingPhone = data.shipping_phone),
          (this.shippingEmail = data.shipping_email);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #0b9b7d;
  background-color: #0b9b7d;
}
.tab-wrapper {
  border-bottom: 2px solid var(--gray-750);
  /* border-bottom: none; */
}

.customer-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
}

.customer-tabs >>> .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}

.action-btn {
  height: 50px;
  min-width: 120px;
}

.action-btn-save {
  height: 50px;
  min-width: 120px;
}

.action-btn-save:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
